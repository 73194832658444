import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { LoginBlock, LoginError } from './LoginBlock';
import AuthService from 'services/auth.service';
import { login } from 'utils/helpers/browser/login';
import { getTranslationKey, useTranslation } from 'utils/localization';
import { getRoute } from 'constants/routes';
import { TNamespaceEnum } from 'types/types';
import {
    MATURED_REGISTRATION_URL_PATH,
    ANONYMOUS_PAYMENT_URL_PATH,
} from 'constants/general';

const Login = (): ReactElement => {
    const { t } = useTranslation();
    const router = useRouter();

    const handleLogin = async (
        email: string,
        password: string
    ): Promise<LoginError> => {
        const response = await new AuthService().login({ email, password });

        if (response.access_token && response.refresh_token) {
            login(response.access_token, response.refresh_token);

            let url = getRoute('/home');

            if (router.query.redirect) {
                url = decodeURIComponent(router.query.redirect as string);
            }
            window.pageRedirect(url);
            return;
        }

        const conversions: Record<
            string,
            { textKey: string; linkKey?: string; linkHref?: string }
        > = {
            user_has_matured: {
                textKey: getTranslationKey(
                    TNamespaceEnum.login,
                    'error.userHasMatured'
                ),
                linkKey: getTranslationKey(
                    TNamespaceEnum.login,
                    'adultProfileRegistrationPage'
                ),
                linkHref: MATURED_REGISTRATION_URL_PATH,
            },
            not_paid_member: {
                textKey: getTranslationKey(
                    TNamespaceEnum.login,
                    'error.memberNotPaid'
                ),
                linkKey: getTranslationKey(
                    TNamespaceEnum.login,
                    'anonymousPaymentPage'
                ),
                linkHref: ANONYMOUS_PAYMENT_URL_PATH,
            },
            user_not_verified: {
                textKey: getTranslationKey(
                    TNamespaceEnum.landingPage,
                    'error.userNotVerified'
                ),
            },
            user_locked: {
                textKey: 'error.userLocked',
            },
            blocked_user: {
                textKey: getTranslationKey(
                    TNamespaceEnum.login,
                    'error.userBlocked'
                ),
            },
        };

        const converted = conversions[response?.error] ?? {
            textKey: getTranslationKey(
                TNamespaceEnum.login,
                'error.reviewLogInDetails'
            ),
        };

        return {
            message: t(converted.textKey),
            linkContent: converted.linkKey ? t(converted.linkKey) : null,
            linkHref: converted.linkHref,
        };
    };

    return (
        <LoginBlock
            loginCb={handleLogin}
            email={router.query.email as string}
            showForgottenPasswordBlock
        />
    );
};

export default Login;
